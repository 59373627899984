export const firebaseConfig = {
  apiKey: "AIzaSyDQmgEdrkTIy2y6UJxHiayKiyZ8lU6CamM",
  authDomain: "nounslove-b8500.firebaseapp.com",
  projectId: "nounslove-b8500",
  storageBucket: "nounslove-b8500.appspot.com",
  messagingSenderId: "731562541816",
  appId: "1:731562541816:web:6135349a34011d1fe2f10d",
  measurementId: "G-8LC43PF8MC"
};

export const ethereumConfig = {
  chainId: '0x1', // rinkeby
  networdName: "Mainnet", 
  alchemyName: undefined,
  contractAddress: "0xb1B25Eeb1026cB947b3f65a5cc123FC28B13EEe6", 
  openseaUrl: "https://opensea.io",
};
